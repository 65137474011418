import './css/Sidebar.scss'
import { useState, useRef, useEffect, useContext } from "react";
import { useNavigate, useLocation } from 'react-router-dom';


/*  COMPONENTS  */
import {
    Title,
    CustomModal,
    CustomButton,
} from '../components';


import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';

import { MenuAreaModel, MenuOptionModel } from '../models';
import importer from '../helpers/importer';

import Slide from '@mui/material/Slide';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';


import { ctxSession, ctxSettings } from '../store';

import { stringAvatar } from '../helpers/tools';
import Axios from '../helpers/axios';
import config from '../config';

import language from '../languages';


const Sidebar = (props: any) => {

    const navigate = useNavigate();
    const location = useLocation();
    
    const session = useContext(ctxSession);
    const settings = useContext(ctxSettings);
    
    const lang = language(settings?.data?.lang);

    const {isOpened, setIsOpened} = props;
    


    const toggleMenu = () => {
        setIsOpened(!isOpened);
    }

    const [menuWindow, setMenuWindow] = useState<any>({isOpened: false, top: 0});
    const openMenuWindow = (e:any) => {
        const {y} = e.target.getBoundingClientRect()
        setMenuWindow({isOpened: true, top: y});
    };
    const closeMenuWindow = () => {
        setMenuWindow({isOpened: false, top: 0})
    };


    const [menu, setMenu] = useState<MenuAreaModel[]>([]);
    const [selectedArea, setSelectedArea] = useState<MenuAreaModel>();
    const [subMenu, setSubMenu] = useState<MenuOptionModel[]>([]);
    const [windowSubMenu, setWindowSubMenu] = useState<any>({label: '', subs: []});


    useEffect( () => {
        const menu = localStorage.getItem('menu');
        if(menu){
            setMenu( JSON.parse(menu) )
        }else{
            const onSuccess = (response:any) => {
                const menu = response.data.data;
                localStorage.setItem('menu', JSON.stringify(menu) );
                setMenu(menu);
            }
            const onError = () => {
            }
            
            Axios(session, 'get', `${config.api.internal}/Settings/menu`, onSuccess, onError);
        }

        
        const settingsLS = localStorage.getItem('settings');
        if(settingsLS){
            if(settings && settings.set){
                settings.set(JSON.parse(settingsLS));
            }
        }else{
            const onSuccess = (response:any) => {
                const settingsRES = response.data.data;
                localStorage.setItem('settings', JSON.stringify(settingsRES) );

                if(settings && settings.set){
                    settings.set(settingsRES);
                }

            }
            const onError = () => {
            }
            
            Axios(session, 'get', `${config.api.internal}/Settings`, onSuccess, onError);
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    

    useEffect( () => {
        if(menu.length){
            for(const area of menu){
                for(const option of area.subs){
                    if(location.pathname === option.path){
                        setSelectedArea(area)
                        setSubMenu(area.subs)
                        break;
                    }
                }
            }
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [menu, isOpened]);

    const refSidebar = useRef<HTMLElement>(null);
    const refSidebarMenu = useRef<HTMLDivElement>(null);
    const topSideRef = useRef<HTMLDivElement>(null);
    const topMenuRef = useRef<HTMLDivElement>(null);
    const menuRef = useRef<HTMLDivElement>(null);
    const bottomSideRef = useRef<HTMLDivElement>(null);
    const bottomMenuRef = useRef<HTMLDivElement>(null);

    const [showScrollArrow, setShowScrollArrow] = useState<boolean>(false);

    useEffect(() => {
        const showArrow = () => {
            if(menuRef.current){
                // console.log(menuRef.current.scrollTop, menuRef.current.scrollHeight, menuRef.current.clientHeight)
                if( menuRef.current.clientHeight < menuRef.current.scrollHeight
                    &&
                    (menuRef.current.scrollTop + menuRef.current.clientHeight) < menuRef.current.scrollHeight
                ){
                    setShowScrollArrow(true);
                }else{
                    setShowScrollArrow(false);
                }
            }
        }
            
        const observer = new ResizeObserver((entries) => {
            for (let entry of entries) {
                if (entry.target === menuRef.current) {
                    showArrow();
                }
            }
        });

        if (menuRef.current) {   // Collega l'osservatore alla tabella
            observer.observe(menuRef.current);
            menuRef.current.addEventListener('scroll', showArrow);
        }
        
        return () => {
            if (menuRef.current) {
                observer.unobserve(menuRef.current);
                menuRef.current.removeEventListener('scroll', showArrow);
            }
        }
    }, []);
    
    useEffect(() => {
        if(isOpened){
            // Funzione di gestione del clic al di fuori del div
            const handleClickOutside = (event:any) => {
                // console.log(event)
              if (refSidebar.current && !refSidebar.current.contains(event.target) && refSidebarMenu.current && !refSidebarMenu.current.contains(event.target)) {
                // Clic al di fuori del div, eseguire l'azione desiderata qui
                setIsOpened(false);
              }
            };
        
            // Aggiunge un event listener al documento per gestire i clic
            document.addEventListener('click', handleClickOutside);
        
            // Pulisce l'event listener quando il componente si smonta
            return () => {
              document.removeEventListener('click', handleClickOutside);
            };
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpened]);
    
    
    const [isLoggingOut, setIsLoggingOut] = useState<boolean>(false);

    
    const logout = () => {
        const onSuccess = () => {
            // console.log('Navbar logout');
            if(session && session.set){
                localStorage.clear();
                session.set(null);
            }
        }
        const onError = () => { }
        
        Axios(session, 'get', `${config.api.internal}/Auth/logout`, onSuccess, onError);
    };


    
    const support = localStorage.getItem('support') === '1';

    
    const printMenuItem = (area:any, isHomeButton:boolean = false) => 
        <div className={`item ${selectedArea?.id === area.id ? 'selected' : ''} ${isHomeButton ? ' home' : ''}`}
            onAuxClick={(e) => {
                if(area.subs.length === 1){
                    const option = area.subs[0];
                    if (option.enabled && option.path && e.button === 1) {   // Apri in una nuova scheda solo se è stato cliccato il tasto della rotellina
                        window.open(option.path, '_blank');
                    }
                }
            }}
            onClick={(e:any) => {
                if(area.subs.length === 1){
                    const option = area.subs[0];
                    if(option.enabled && option.path){


                        navigate(option.path);
                        setSelectedArea(area);
                        setSubMenu(area.subs);

                        setIsOpened(false);
                    }
                }
                if(isOpened){   // setta il submenu solo se aperto
                    setSubMenu(area.subs);
                    setSelectedArea(area);
                }
                
            }}
            onMouseEnter={ (e) => {
                if(!isOpened && area.subs.length > 1){  // popola il submenu della window
                    setWindowSubMenu({
                        label: area.label,
                        subs: area.subs
                    });
                    openMenuWindow(e);
                }
            }}
            onMouseLeave={ (e) => {
                closeMenuWindow();
            }}
        >
            <img className="protect" src={importer.ic.require(area.icon)} alt="" />
        </div>;


    return (
            <>
            <Slide direction="right" in={isOpened}>
                    <div ref={refSidebarMenu} id="menu" className={ "menu " + (isOpened ? 'opened' : '')}>
                            <div className="pageTitle">
                                <h4>{selectedArea?.label ? lang[selectedArea?.label] : selectedArea?.label}</h4>
                            </div>
                            <div className='menuItems'>
                                <div className="items">
                                    { subMenu.length > 0 && subMenu.map((option:any, k:number) => 
                                            <a key={k} 
                                                href={option.path}
                                                className={`item ${option.enabled ? '' : 'disabled'} ${location.pathname === option.path ? 'selected' : ''}`}
                                                onAuxClick={(e:any) => {
                                                    if (option.enabled && option.path && e.button === 1) {   // Apri in una nuova scheda solo se è stato cliccato il tasto della rotellina
                                                        window.open(option.path, '_blank');
                                                    }
                                                }}
                                                onClick={(e:any) => {
                                                    e.preventDefault();
                                                    if(option.enabled && option.path){
                                                        setIsOpened(false);
                                                        navigate(option.path);
                                                    }
                                                }}
                                            >
                                                <img className="protect" src={importer.ic.require(option.icon)} alt="" />
                                                <h5>{ lang[option.label] ?? option.label }</h5>
                                            </a>
                                        )
                                        
                                    }
                                </div>
                            </div>

                            <div className='profile'>
                                <div>
                                    <h4>{`${session?.data?.userData?.firstname} ${session?.data?.userData?.lastname}`}</h4>
                                    <h5>@{session?.data?.userData?.username}</h5>
                                </div>
                                <div className="item"
                                    onClick={() => {
                                        setIsLoggingOut(true);
                                    }}
                                >
                                    <img className="protect" src={importer.ic.require('logoutWhite.svg')} alt="" />
                                </div>

                            </div> 
                    </div>
            </Slide>



            <aside id="gestSidebar" ref={refSidebar}>
                    { menuWindow.isOpened &&
                        <div id="menuWindow" style={{top: menuWindow.top}} onMouseEnter={() => setMenuWindow({isOpened: true, top: menuWindow.top}) } onMouseLeave={() => { closeMenuWindow() }}>
                            <div className='window'>
                                <div className="pageTitle">
                                    <h4>{ lang[windowSubMenu.label] }</h4>
                                </div>
                                { windowSubMenu.subs.length && windowSubMenu.subs.map((option:any, k:number) =>     
                                        <a key={k}
                                            href={option.path}
                                            className={`item ${option.enabled ? '' : 'disabled'} ${location.pathname === option.path ? 'selected' : ''}`}
                                            onAuxClick={(e:any) => {
                                                if (option.enabled && option.path && e.button === 1) {   // Apri in una nuova scheda solo se è stato cliccato il tasto della rotellina
                                                    window.open(option.path, '_blank');
                                                }
                                            }}
                                            onClick={(e:any) => {
                                                e.preventDefault();
                                                if(option.enabled && option.path){
                                                    const area = menu[(menu.findIndex((v:any) => v.id === option.areaID))];
                                                    setSelectedArea(area);
                                                    setSubMenu( area.subs );

                                                    navigate(option.path);
                                                    // console.log(option.path)
                                                }
                                            }}
                                        >
                                            <img className="protect" src={importer.ic.require(option.icon)} alt="" />
                                            <h5>{lang[option.label] ?? option.label}</h5>
                                        </a>
                                    )
                                }
                            </div>
                        </div>
                    }

                    <div ref={topSideRef} className='topSide'>
                        <div ref={topMenuRef} className="topMenu">
                            <div className="item"
                                onClick={() => { toggleMenu() } }
                            >
                                { isOpened ? <img src={importer.ic.require('menuClose.svg')} alt="" /> : <img src={importer.ic.require('menuOpen.svg')} alt="" />}
                            </div>
                            { menu.length > 0 &&
                                printMenuItem(menu[0], true)
                            }
                        </div>
                        <div ref={menuRef} className="menu">
                            { menu.length > 0 &&
                                menu.map((area:any, k:number ) => {
                                    if(!k) return <></>;
                                    return printMenuItem(area);
                                })
                            }
                        </div>
                        
                        { showScrollArrow == true &&
                            <div className='scrollBelow'>
                                <ArrowDropDownRoundedIcon />
                            </div>
                        }
                    </div>

                    <div ref={bottomSideRef} className='bottomSide'>
                        <div ref={bottomMenuRef} className="bottomMenu">
                            {
                            support && ( session?.data?.userData?.email || session?.data?.userData?.role === 'admin' )
                            ?
                            <Tooltip title="Support" placement="right">
                                <div className="item" onClick={() => { window.open(config.platforms.support, '_blank'); }}>
                                    {/* <img className="protect" src={importer.ic.require('support.svg')} alt="" /> */}
                                    <HelpOutlineRoundedIcon className='text-white' />
                                </div>
                            </Tooltip>
                            :
                            <></> 
                            }
                            { !isOpened &&
                                <div className="item"
                                    onClick={() => {
                                        setIsLoggingOut(true);
                                    }}
                                >
                                    <img className="protect" src={importer.ic.require('logoutWhite.svg')} alt="" />
                                </div>
                            }
                        </div>

                        <div className="account">
                            <Avatar {...stringAvatar(session?.data?.userData?.fullname)} />
                        </div>
                    </div>
                    
                <CustomModal
                    isOpen={isLoggingOut}
                    
                    onClose={() => {
                        setIsLoggingOut(false);
                    }}
                    closeButton={true}
                    footer={<>
                        <CustomButton
                            variant="secondary"
                            onClick={() => {
                                setIsLoggingOut(false);
                            }}
                        >
                            {lang.cancel}
                        </CustomButton>
                        <CustomButton
                            onClick={logout}
                        >
                            {lang.logout}
                        </CustomButton>
                    </>}
                >
                    <img height="100" width="100" style={{margin: 'auto'}} src={importer.img.require('exit.png')} alt="" />
                    <Title class="m-2">{lang.really_want_logout}</Title>
                    
                </CustomModal>
            </aside>
            
            </>
    );
};
export default Sidebar;