import './DigitalCounter.scss';    // import './css/Caption.scss';


import React from 'react';

// import { Box } from '@mui/material';


const DigitalCounter: React.FC<any>
= ({
    class: classProp,
    value,
    unit,
    variant
}) => {
    
    let className : string[] = ["digitalCounter"];

    const valueString = value.toString().split('');

    switch(variant) {
        case 'quadrant': {
            className.push('quadrant');
            break;
        }
        case 'single-digit': 
        default: {
            className.push('single-digit');
            break;
        }
    }

    if(classProp) className.push(classProp);
    
    return (
        <div className={className.join(' ')}>
            { valueString.map((v:string, i:number) =>
                <span key={i} className={v === '.' || v === ',' ? 'dot' : 'number' }>
                    {v}
                </span>
            )
            }
            {   unit !== undefined &&
                <span className={'unit'}>
                    { unit }
                </span>
            }
        </div>
    );
}

export default React.memo(DigitalCounter);