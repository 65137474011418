import './css/ToPolyline.scss';
import { useRef, useState, useEffect, useContext, useCallback, FormEvent } from 'react';

/*  COMPONENTS  */
import {
    Title,
    Card,
    CustomTab,
    CustomInput,
    CustomButton,
    CustomSelect,
} from '../components';


import CasinoIcon from '@mui/icons-material/Casino';

import Fade from '@mui/material/Fade';


import { GoogleMap, Polygon, InfoWindow, Autocomplete, DrawingManager } from '@react-google-maps/api';
import { ctxSession, ctxSnackbar } from '../store';


import Axios from '../helpers/axios';
import config from '../config';

import formDataValidator from '../helpers/formData';
import { randomInRange, globalCenter } from '../helpers/tools';


import language from '../languages';
import { centerCalculator } from '../helpers/tools'


const ToPolyline = () => {

    const session = useContext(ctxSession);
    
    
    const snackbar = useContext(ctxSnackbar);

    const lang = language();

    function extractCoordinatesFromKML(xml:string) {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(xml, "text/xml");
      
        const coordinatesNode = xmlDoc.getElementsByTagName("coordinates");

        let coords : any[] = [];
        if(coordinatesNode){
            
            Array.from(coordinatesNode).forEach((v:any, i:number) => {
                coords.push(v.textContent);
                })

            if(coordinatesNode && coords){
                return coords;
            }else{
                return null;
            }
        }else{
            return null;
        }
    }
    
    function geojsonToPolyline(geojsonObject:any) {
        if (!geojsonObject.type) {
            throw new Error('Il GeoJSON deve avere una proprietà "type".');
        }
    
        const coordinates = [];
        
        if (geojsonObject.type === 'Feature') {
            if (!geojsonObject.geometry) {
                throw new Error('L\'oggetto GeoJSON di tipo "Feature" deve avere una proprietà "geometry".');
            }
            geojsonObject = geojsonObject.geometry; // Usa la geometria interna
        }
    
        // Gestisci diversi tipi di geometrie
        switch (geojsonObject.type) {
            case 'Point':
                const point = {
                    lat: geojsonObject.coordinates[1], // Latitudine
                    lng: geojsonObject.coordinates[0]  // Longitudine
                };
                coordinates.push(point);
                break;
            case 'MultiPoint':
                geojsonObject.coordinates.forEach((coord:any) => {
                    const point = {
                        lat: Number(coord[1].toFixed(6)), // Latitudine
                        lng: Number(coord[0].toFixed(6))   // Longitudine
                    };
                    coordinates.push(point);
                });
                break;
            case 'LineString':
                geojsonObject.coordinates.forEach((coord:any) => {
                    const point = {
                        lat: Number(coord[1].toFixed(6)), // Latitudine
                        lng: Number(coord[0].toFixed(6))   // Longitudine
                    };
                    coordinates.push(point);
                });
                break;
            case 'MultiLineString':
                geojsonObject.coordinates.forEach((lineString:any) => {
                    lineString.forEach((coord:any) => {
                        const point = {
                            lat: Number(coord[1].toFixed(6)), // Latitudine
                            lng: Number(coord[0].toFixed(6))   // Longitudine
                        };
                        coordinates.push(point);
                    });
                });
                break;
            case 'Polygon':
                geojsonObject.coordinates.forEach((ring:any) => {
                    ring.forEach((coord:any) => {
                        const point = {
                            lat: Number(coord[1].toFixed(6)), // Latitudine
                            lng: Number(coord[0].toFixed(6))  // Longitudine
                        };
                        coordinates.push(point);
                    });
                });
                break;
            case 'MultiPolygon':
                geojsonObject.coordinates.forEach((polygon:any) => {
                    polygon.forEach((ring:any) => {
                        const formattedRing:any[] = [];
                        ring.forEach((coord:any) => {
                            const point = {
                                lat: Number(coord[1].toFixed(6)), // Latitudine
                                lng: Number(coord[0].toFixed(6))  // Longitudine
                            };
                            formattedRing.push(point);
                        });
                        coordinates.push(formattedRing);
                    });
                });
                
                break;
            default:
                throw new Error('Tipo di geometria GeoJSON non supportato.');
        }
        
    
        return coordinates;
    }
    
    
    const [center, setCenter] = useState<any>(null);
    const [polygon, setPolygon] = useState<any[]>([]);
    const [value, setValue] = useState<string>('no XML');
    const [textAreaValue, setTextAreaValue] = useState<string>('');

    const modes = [
        {
            value: 'KML',
            label: 'KML'
        },
        {
            value: 'GeoJSON',
            label: 'GeoJSON'
        },
    ];
    const [selectedMode, setSelectedMode] = useState<string>('KML');


    useEffect(() => {
        console.log(center)
    }, [center])

    const handleChange = useCallback( (e:any) => {

        let value = e.target.value;
        setTextAreaValue(value);

        let coordinates : any[] = [];

        switch(selectedMode){
            case 'KML': {
                const rawCoords = extractCoordinatesFromKML(value);
                if(rawCoords && rawCoords.length){
        
        
                    rawCoords.forEach((rawCoord:any, i:number) => {
                        let area = rawCoord.replaceAll(',0', '').split('\n');
                        const coords = area.map((v:string, i:number) => {
                            const tmp = v.replaceAll(' ', '').split(',');
                            const lat = parseFloat( tmp[1] );
                            const lng = parseFloat( tmp[0] );
        
        
                            return {
                                lat,
                                lng
                            }
                        }).filter( (v:any, i:number) => v.lat && v.lng);
                        
                        coordinates.push(coords);
                    });
                    
        
                    setCenter( centerCalculator(coordinates) );
                    setPolygon( coordinates );
                    setValue( JSON.stringify(coordinates) );
                }else{
                    setValue( 'invalid XML' );
                    setPolygon( [] );
                    setCenter(null);
                }
                break;
            }
            case 'GeoJSON': {
                try {
                    value = JSON.parse(value);
                    
                    const temp = geojsonToPolyline(value);
                    if(Array.isArray(temp[0])){
                        coordinates = temp;
                    }else{
                        coordinates.push(geojsonToPolyline(value));
                        
                    }
                    console.log(coordinates)
                    
                    if(coordinates.length){
                        setCenter( centerCalculator(coordinates) );
                        setPolygon( coordinates );
                        setValue( JSON.stringify(coordinates) );
                    }else{
                        setPolygon( [] );
                        setCenter(null);
                    }
                }catch(exception:any){

                    console.log(exception)
                    setValue( 'invalid GeoJSON' );
                    setPolygon( [] );
                    setCenter(null);
                }
                
                break;
            }
        }
    }, [selectedMode]);



    const [selectedAreaID, setSelectedAreaID] = useState<number>(-1);
    const [options, setOptions] = useState<any[]>([]);
    const areasRef = useRef<any[]>([]);

    const [ currentMarker, setCurrentMarker ] = useState<any>(null);



    useEffect( () => {
        const onSuccess = (response: any) => {
            areasRef.current = response.data.data;

            
            setOptions(areasRef.current.map( (v:any, i:number) => {
                return {
                    value: v.id,
                    label: v.name,
                    data: v.geoArea
                }
            }));
        };

        const onError = () => {

        };

        Axios(session, 'get', `${config.api.internal}/AdminTools/Areas`, onSuccess, onError);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    

    const saveArea = useCallback((e:FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        

        let formData:any = formDataValidator(e.target, 'object');
        if(value !== 'invalid GeoJSON' && value !== 'no XML' && value !== 'invalid XML' && value !== 'invalid GeoJSON' && value !== 'no GeoJSON' && value !== '' ){
        //     snackbar?.set({message: lang['successfully_updated'], severity: 'warning'});
        //     return;
        // }else{
            formData.geoArea = value;
        }


        const onSuccess = (response: any) => {
            if(selectedAreaID !== -1){
                
                areasRef.current = areasRef.current.map((v:any) => {
                    if(v.id === selectedAreaID){

                        if(formData.geoArea){
                            v.geoArea = formData.geoArea
                        }

                        if(formData.geoLabelLat && formData.geoLabelLng){
                            v.geoLabelLat = Number(formData.geoLabelLat);
                            v.geoLabelLng = Number(formData.geoLabelLng);
                        }
                    }
                    return v; 
                });
                
                setOptions(areasRef.current.map((v:any) => {
                    if(v.id === selectedAreaID){
                        if(formData.geoArea){
                            v.geoArea = JSON.parse(formData.geoArea);//formData.geoArea
                        }
                    }
                    return {
                        value: v.id,
                        label: v.name,
                        data: v.geoArea
                    }
                }));
                
            }
            snackbar?.set({message: lang.successfully_updated(), severity: 'success'});
        };

        const onError = () => {
            snackbar?.set({message: 'server_error', severity: 'error'});
        };
        


        Axios(session, 'post', `${config.api.internal}/AdminTools/Areas`, onSuccess, onError, formData);
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAreaID, areasRef.current, value, currentMarker]);

    
    const [currentZIndex, setCurrentZIndex] = useState<number|null>(null);

    const saveZIndex = useCallback((e:FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        let formData:any = formDataValidator(e.target, 'object');
        // formData.geoAreaZIndex = currentZIndex;


        const onSuccess = (response: any) => {
            if(selectedAreaID !== -1){
                
                areasRef.current = areasRef.current.map((v:any) => {
                    if(v.id === selectedAreaID){
                        v.geoAreaZIndex = currentZIndex
                    }
                    return v; 
                });
                
                
            }
            snackbar?.set({message: lang.successfully_updated(), severity: 'success'});
        };

        const onError = () => {
            snackbar?.set({message: 'server_error', severity: 'error'});
        };


        Axios(session, 'post', `${config.api.internal}/AdminTools/Areas`, onSuccess, onError, formData);
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAreaID, areasRef.current, currentZIndex]);



    const [ refresh, setRefresh ] = useState(Math.random());

    
    return (
        <Fade in={true} timeout={{enter: 400}}>
            <div id="ToPolyline">
                <main>
                    <Card>
                        <CustomTab
                            tabs={[
                                {
                                    title: "Polyline & Label",
                                },
                                {
                                    title: "zIndex",
                                }
                            ]}
                        >
                            <div className="ToPolylineConverter">
                                <div>
                                    <div>
                                        
                                        <Title>Source</Title>
                                        
                                        <CustomSelect
                                            options={modes}
                                            value={selectedMode}
                                                onChange={ (value: any, data:any, selectedOption:any, ref:any) => {
                                                setSelectedMode(value);
                                            } }
                                        />
                                    </div>
                                    <textarea name="" id="" value={textAreaValue} onChange={handleChange}></textarea>
                                </div>
                                <div>
                                    <div>
                                        <Title>Result</Title>
                                    </div>
                                    <div>
                                        <textarea value={value} readOnly>
                                        </textarea>
                                        <div className='resultStats'>
                                            <span>Punti: {polygon.length}</span> <span>Lunghezza: {value.length}</span>
                                        </div>
                                    </div>
                                <GoogleMap
                                    mapContainerStyle={{ width: '100%', height: '100%', borderRadius: "10px" }}
                                    center={center || {lat: 41.890208, lng: 12.492278}}
                                    zoom={12}
                                    options={{ minZoom: 3, fullscreenControl: true }}
                                >
                                
                                    { polygon &&
                                        <Polygon
                                            paths={polygon}
                                            options={{
                                                fillColor: '#f00', // Imposta il colore verde
                                                fillOpacity: .3,
                                                strokeColor: '#fff',
                                                strokeOpacity: .5,
                                                strokeWeight: 1,
                                            }}
                                            
                                            onClick={(e:any) => {
                                                setCurrentMarker({
                                                            lat: Number(e.latLng.lat().toFixed(6)),
                                                            lng: Number(e.latLng.lng().toFixed(6))
                                                        });
                                            }}
                                        />
                                    }
                                    { currentMarker &&
                                        currentMarker.lat &&
                                        currentMarker.lng &&
                                        <InfoWindow
                                            position={currentMarker}
                                        >
                                            <span className='mapLabel stat'>
                                                {/* {<div className='label'>
                                                TEST LABEL
                                                </div> } */}
                                                <div className='value'>
                                                LABEL
                                                </div>
                                            </span>

                                        </InfoWindow>
                                        
                                    }
                                </GoogleMap>
                                </div>
                                <div>
                                    <form onSubmit={saveArea}>
                                        <CustomSelect
                                            name="id"
                                            options={options}
                                            value={selectedAreaID}
                                            onChange={ (value: any, data:any, selectedOption:any, ref:any) => {
                                                setTextAreaValue('');
                                                setValue('');


                                                setSelectedAreaID(value);
                                                const area = areasRef.current.find((v:any) => v.id === value );
                                                // console.log(area)
                                                if(!area.geoLabelLat || !area.geoLabelLng){
                                                    setCurrentMarker(null);
                                                }else{
                                                    setCurrentMarker({lat: area.geoLabelLat, lng: area.geoLabelLng});
                                                }
                                                
                                                const coords = data; //JSON.parse(data);
                                                if(coords){
                                                    setPolygon( coords );
                                                    setCenter( centerCalculator(coords) );
                                                }
                                                else{
                                                    setPolygon( [] );
                                                }
                                            }}
                                        />
                                        {currentMarker
                                            ?
                                            <>
                                                {
                                                    currentMarker.lat
                                                    &&
                                                    <input type="hidden" name="geoLabelLat" value={currentMarker.lat}/>
                                                }
                                                {
                                                    currentMarker.lng
                                                    &&
                                                    <input type="hidden" name="geoLabelLng" value={currentMarker.lng} />
                                                }
                                            </>
                                            :
                                            <></>
                                        }
                                        <CustomButton type="submit">
                                            Save
                                        </CustomButton>
                                    </form>
                                </div>
                            </div>

                            <div className="zIndex">
                                <div className='map'>
                                    <GoogleMap
                                        mapContainerStyle={{ width: '100%', height: '100%', borderRadius: "10px" }}
                                        center={ selectedAreaID !== -1 && areasRef.current.find((v:any) => v.id === selectedAreaID ).geoArea ? centerCalculator( areasRef.current.find((v:any) => v.id === selectedAreaID ).geoArea /* */ )
                                            :
                                            (globalCenter(areasRef.current
                                                                .filter((v:any) => v.geoArea)
                                                                .map((v:any) => {
                                                                    return {
                                                                        geoArea: v.geoArea /*JSON.parse(v.geoArea)*/
                                                                    }
                                                                })) || {lat: 41.890208, lng: 12.492278})
                                                            }
                                        zoom={selectedAreaID ? 12 : 10}
                                        options={{ minZoom: 3, fullscreenControl: true }}
                                    >
                                    {
                                    areasRef.current &&
                                    areasRef.current.length > 0 &&
                                    refresh &&
                                    areasRef.current.filter((area:any, i:number) => area.geoArea != null)
                                    .map((area:any, i: number) => {
                                        return <Polygon
                                            paths={ area.geoArea /*JSON.parse(area.geoArea)*/ }
                                            options={{
                                                fillColor: `rgba(${randomInRange(239)},${randomInRange(239)},${randomInRange(239)})`, // Imposta il colore verde
                                                fillOpacity: .67,
                                                strokeColor: selectedAreaID && selectedAreaID === area.id ? '#004162' : '#fff',
                                                strokeOpacity: selectedAreaID && selectedAreaID === area.id ? 1 : 0.9,
                                                strokeWeight: selectedAreaID && selectedAreaID === area.id ? 3 : 2,
                                                zIndex: selectedAreaID && selectedAreaID === area.id && currentZIndex !== area.geoAreaZIndex ? currentZIndex : area.geoAreaZIndex
                                                // selectedAcurrentZIndex && currentZIndex > area.geoAreaZIndex ? currentZIndex : area.geoAreaZIndex
                                            }}
                                            onClick={(v:any) => {
                                                if(selectedAreaID === area.id){
                                                    setSelectedAreaID(-1);
                                                    setCurrentZIndex(null);
                                                    return;
                                                }
                                                setSelectedAreaID(area.id);
                                                setCurrentZIndex(area.geoAreaZIndex);
                                            }}
                                        />
                                        }
                                    )
                                    }
                                    </GoogleMap>
                                </div>
                                <div className='tools'>
                                    <form onSubmit={saveZIndex}>
                                        
                                        <CustomButton
                                            class="mb-2"
                                            onClick={(e:any) => {
                                                e.preventDefault();

                                                setRefresh(Math.random())
                                            }}
                                        >
                                            <CasinoIcon style={{transform: 'rotate(-30deg)', fill: 'white'}} />
                                            Refresh
                                        </CustomButton>

                                        <CustomSelect
                                            name="id"
                                            options={options}
                                            value={selectedAreaID}
                                            onChange={ (value: any, data:any, selectedOption:any, ref:any) => {
                                                setSelectedAreaID(value);
                                                
                                                setCurrentZIndex(areasRef.current.find((area:any) => area.id === value).geoAreaZIndex);

                                                const coords = data;//JSON.parse(data);
                                                if(coords){
                                                    setPolygon( coords )
                                                    setCenter( centerCalculator(coords) );
                                                }
                                            } }
                                        />

                                        {
                                            currentZIndex
                                            ?
                                            <CustomInput
                                                type="number"
                                                name="geoAreaZIndex"
                                                value={currentZIndex}
                                                onChange={(value:any) => {
                                                    setCurrentZIndex(value);
                                                }}
                                                max={8}
                                                min={1}
                                            />
                                            :
                                            <></>
                                        }
                                            
                                        <CustomButton type="submit">
                                            Save
                                        </CustomButton>

                                        
                                    </form>
                                </div>
                            </div>
                        </CustomTab>
                    </Card>
                </main>
            </div>
        </Fade>
    );
};
export default ToPolyline;


// http://localhost:3001/