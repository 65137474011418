import './css/ConsumptionMonitor.scss';
import React, { useRef, useState, useEffect, useMemo, useCallback, useContext } from 'react';

import { ReactSVG } from "react-svg";

/*  COMPONENTS  */
import {
    Title,
    Card,
    CustomSelect,
    CustomTable,
    CustomTab,
    DateRangePicker,
    CustomInput,
    CustomButton,
    CustomGroup,
    CustomIconButton,
    DigitalCounter
} from '../components';

import ApexRadialChart from '../components/apex/RadialChart/RadialChart'
import Test from '../components/apex/test/test'

/*  ICONS    */
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import LocationCityRoundedIcon from '@mui/icons-material/LocationCityRounded';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import PersonSearchRoundedIcon from '@mui/icons-material/PersonSearchRounded';


import RadialChart from '../components/charts/RadialChart';

import { ctxSession, ctxSettings } from '../store';


/*  MUI COMPONENTS  */
import Fade from '@mui/material/Fade';

import language from '../languages';


import { LineChart, Line, BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

/*  HELPERS */
import config from '../config';
import Axios from '../helpers/axios';
import importer from '../helpers/importer';
import localProps from '../helpers/localProps';
import Datetime from '../helpers/datetime';





const ConsumptionMonitor = () => {
    const session = useContext(ctxSession);
    const settings = useContext(ctxSettings);
    const lang = language(settings?.data?.lang);

    
    const [interval, setInterval] = useState<any>({
        start: new Datetime().getDate(),
        end: new Datetime().getDate(),
    });

    const [endUsersLoading, setEndUsersLoading] = useState<boolean>(true);
    const [endUsers, setEndUsers] = useState<any[]>([]);

    const [selectedArea, setSelectedArea] = useState<any|undefined>(undefined);
    
    const [options, setOptions] = useState<any>({
        areas: []
    });


    const endUsersColumnsRef =
        useMemo<any[]>(() => [
        {
            name: 'alarm',
            displayName: <WarningAmberRoundedIcon />,//lang.alarms,
            render: (row:any) => row.alarm ?? '',
            width: 70
        },
        {
            name: 'CodiceUtente',
            displayName: lang.ciu,
            width: 120,
            resizable: true,
            draggable: true
        },
        {
            name: 'Intestatario',
            displayName: lang.accountholder,
            clamp: 1,
            resizable: true,
            draggable: true,
            width: 180,
        },
        {
            name: 'indirizzoInstallazione',
            displayName: lang.address,
            hide: true
        },
        {
            name: 'Civico',
            displayName: lang.house_number,
            hide: true
        },
        {
            name: 'address',
            displayName: lang.address,
            searchInRender: true,
            width: 180,
            render: (row:any) => `${row.indirizzoInstallazione} ${row.Civico}`,
            clamp: 1,
            resizable: true,
            draggable: true
        },
        {
            name: 'Matricola',
            displayName: lang.serial,
            resizable: true,
            draggable: true,
            width: 120,
        },
        {
            name: 'ID_Modulo',
            displayName: lang.module,
            resizable: true,
            draggable: true
        },
        {
            name: 'ID_MarcaContatore',
            displayName: lang.brand,
            resizable: true,
            draggable: true,
        },
        {
            name: 'ID_TipoComunicazione',
            displayName: lang.communication,
            resizable: true,
            draggable: true
        },
        {
            name: 'ID_TipoDevice',
            displayName: lang.type,
            resizable: true,
            draggable: true
        }
    ], [endUsers, settings]);
    

    useEffect( () => {
        /// Early return
        if(selectedArea === undefined) return;


        setEndUsersLoading(true);

        const onSuccess = (response: any) => {
            const { data } = response.data;
            setEndUsers(data);
            setEndUsersLoading(false);
        };

        const onError = () => {
            setEndUsers([]);
            setEndUsersLoading(false);
        };

        Axios(session, 'get', `${config.api.internal}/ConsumptionMonitor/EndUsers`, onSuccess, onError, {id: selectedArea, ...interval});
    }, [options, selectedArea, interval]);


    useEffect(() => {
        // Options
        const onOptionsSuccess = (response: any) => {
            const { data } = response.data;
            
            const areaLS = localProps.global.get('consumptionMonitorArea');
            const area = Number(areaLS);
            if( areaLS && area && data.areas.find((v:any) => v.id === area) ) {
                setSelectedArea(area);
            }else if(data.areas[0].id){
                localProps.global.set('consumptionMonitorArea', data.areas[0].id);
                setSelectedArea(data.areas[0].id);
            }

            
            // const planLS = localProps.global.get('WFMinterventionManagementPlan');
            // const plan = Number(planLS);
            // if( planLS && plan && data.plans.find((v:any) => v.id === plan) ) {
            //     setSelectedPlan(area);
            // }else{
            //     localProps.global.set('WFMinterventionManagementPlan', -1);
            //     setSelectedPlan(-1);
            // }


            setOptions(data);
        };

        const onOptionsError = () => { };

        Axios(session, 'get', `${config.api.internal}/consumptionMonitor/options`, onOptionsSuccess, onOptionsError);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const [mode, setMode] = useState<boolean>(true);
    
    
    return (
        <Fade in={true} timeout={{enter: 400}} >
            <div id="consumptionMonitor">
                <main>

                    <header>
                        <div className='main'>
                        <Title size="extraLarge">Monitor Consumi</Title>

                        </div>
                        <div className='periodSelector'>
                            <CustomGroup
                                variant="separated"
                            >
                                <CustomIconButton
                                    size="sm"
                                >
                                    <ChevronLeftIcon />
                                </CustomIconButton>
                                <DateRangePicker />
                                <CustomIconButton
                                    size="sm"
                                >
                                    <ChevronRightIcon />
                                </CustomIconButton>
                            </CustomGroup>
                            <CustomSelect
                                options={[]}
                            />
                            <CustomSelect 
                                options={[]}
                            />
                            <CustomButton>
                                <DownloadRoundedIcon />
                                {lang.export}
                            </CustomButton>
                        </div>
                    </header>

                    <Card class="header_t1">
                        <CustomButton variant="light"
                            size="sm"
                            onClick={() => {
                                setMode(!mode)
                            }}
                        >
                            { mode ?
                            <ReactSVG src={importer.ic.require('db_search.svg')} /*style={{width: '100%', height: '100%'}}*/ />
                            :
                            <PersonSearchRoundedIcon />
                            }
                        </CustomButton>
                        <CustomGroup style={{width: '100%'}} variant='separated'>
                            { mode ?
                                <>
                                <CustomSelect id="test1"  style={{width: '100%'}} size="sm" placeholder={'Seleziona procedura'} options={[]} />
                                <CustomSelect
                                    size="sm"
                                    icon={<LocationCityRoundedIcon />}
                                    style={{width: 200}}
                                    value={selectedArea}
                                    options={options.areas.map((v:any) => {
                                        return {
                                            value: v.id,
                                            label: v.name,
                                            data: v.geoArea
                                        }
                                    })}
                                    onChange={(value) => {
                                        localProps.global.set('consumptionMonitorArea', value);
                                        setSelectedArea(value);
                                    }}
                                />
                                </>
                                :
                                <>
                                <CustomInput size="sm" />
                                <CustomSelect
                                    size="sm"
                                    icon={<LocationCityRoundedIcon />}
                                    style={{width: 200}}
                                    value={selectedArea}
                                    options={options.areas.map((v:any) => {
                                        return {
                                            value: v.id,
                                            label: v.name,
                                            data: v.geoArea
                                        }
                                    })}
                                    onChange={(value) => {
                                        localProps.global.set('consumptionMonitorArea', value);
                                        setSelectedArea(value);
                                    }}
                                />
                                </>
                            }
                        </CustomGroup>
                            
                    </Card>

                    <Card class='table1'
                        title={"Lista Utenti"}
                        actions={<CustomIconButton variant='primary'><DownloadRoundedIcon /></CustomIconButton>}
                    >
                        <CustomTable
                            stripped={true}
                            customID="CodiceUtente"
                            loading={endUsersLoading}
                            columns={endUsersColumnsRef}
                            rows={endUsers}
                            // hidePerPage={true}
                            perPage={100}
                            endUsersColumnsRef={'ellipsis'}
                            quickFilter={true}
                            selectiveQuickFilter={true}
                            columnFilter={true}
                            multiSort={true}
                            switchDensity={true}
                            selectRows={true}
                        />
                    </Card>

                    
                    <Card class='stat1'
                        title={"Ultima Lettura"}
                    >
                        <DigitalCounter value={22349} unit={"m³"} variant="quadrant" />
                        <Title class='text-dark'>SN 123901238</Title>
                    </Card>
                    <Card class='stat2'
                        title={"Pro Ora"}
                    >
                        {/* <RadialChart max={100} value={60}/> */}
                        <ApexRadialChart />
                    </Card>
                    <Card class='table2'
                        title={"Letture Utente"}
                    >
                        <CustomTable
                            title="test"
                            hidePerPage={true}
                        />
                    </Card>


                    <Card class='graph1'
                        title={"Consumo Medio Orario"}
                    >
                        <ResponsiveContainer width="100%" height="100%">
                            <BarChart
                            width={500}
                            height={300}
                            // data={data2}
                            margin={{
                                top: 20,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                            >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />
                            {/* <Legend /> */}
                            <Bar dataKey="pv" stackId="a" fill="#8884d8" />
                            <Bar dataKey="uv" stackId="a" fill="#82ca9d" />
                            </BarChart>
                        </ResponsiveContainer>
                    </Card>
                    
                    <Card class='table3'>
                        <CustomTab
                            tabs={[{title: lang.alarms}, {title: lang.transmissions}]}
                        >
                            <CustomTable
                                hidePerPage={true}
                            />
                        </CustomTab>
                    </Card>


                    <Card class='graph2'
                        title={"Consumi"}
                    >
                        <Test />
                        {/* <ResponsiveContainer width="100%" height="100%">
                            <LineChart
                            width={500}
                            height={300}
                            // data={data1}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                            >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />
                            {/* <Legend /> 
                            <Line type="monotone" dataKey="pv" stroke="#8884d8" activeDot={{ r: 8 }} />
                            <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
                            </LineChart>
                        </ResponsiveContainer> */}
                    </Card>


                    
                </main>
            </div>
        </Fade>
    );
};
export default React.memo(ConsumptionMonitor);


// http://localhost:3001/