import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const Test = ({
    series: seriesProp = []
}) => {


//   const [series] = useState<any>(seriesProp ?? []);
  const [series] = useState<any>([
        // {
        //     name: 'TEAM A',
        //     type: 'column',
        //     data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30],
        // },
        {
            name: 'TEAM B',
            type: 'area',
            data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43],
            // color: "red",
            color: '#ff0000'
        },
        {
            name: 'TEAM C',
            type: 'line',
            data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39]
        }
      ]);

//   color
//   fill: {
//     opacity: [0.85, 0.1, 1],
//     type: ['solid', 'gradient', 'solid'],
//   }



  const [options] = useState<any>({
    chart: {
        toolbar: {
            show: false,
        },
        parentHeightOffset: 0,
    },
    stroke: {
      width: [2, 5],
      curve: 'smooth'
    },
    plotOptions: {
      bar: {
        columnWidth: '50%',
        // distributed: true
      }
    },
    // colors: ['#ff0000', 'auto'],
    fill: {
        opacity: [0.85, 1],
        type: ['gradient', 'solid'],
        gradient: {
            inverseColors: false,
            shade: 'light',
            type: "vertical",
            opacityFrom: 0.7,
            opacityTo: 0.55,
            stops: [ 100, 100]
        }
    },
    // labels: ['01/01/2003', '02/01/2003', '03/01/2003', '04/01/2003', '05/01/2003', '06/01/2003', '07/01/2003',
    //   '08/01/2003', '09/01/2003', '10/01/2003', '11/01/2003'
    // ],
    // markers: {
    //   size: 0
    // },
    xaxis: {
        show: false,
        labels: {
          show: false,
        },
        tooltip: {
          enabled: false
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false
        },
        // type: 'datetime',
    },
    yaxis: {
        show: false,
        labels: {
          show: false,
        },
    //   title: {
    //     text: 'Points',
    //   },
    //   min: 0
    },
    grid: {
        show: false,
        padding: {
            top: 0,
            right: 0,
            bottom: -20,
            left: 0
        },  
    },
    legend: {
        show: false,
    },
    // tooltip: {
    //     shared: true,
    //     intersect: false,
    //     y: {
    //         formatter: function (y:any) {
    //         if (typeof y !== "undefined") {
    //             return y.toFixed(0) + " points";
    //         }
    //         return y;
    //         }
    //     }
    // }
  });

  return (
    <ReactApexChart options={options} series={series} type="line" height={'100%'} />
  );
}

export default Test;
