import './css/InterventionManagement.scss'

import React, { useRef, useState, useEffect, useMemo, useContext, useCallback } from 'react';

/*  MUI COMPONENTS  */
import Fade from '@mui/material/Fade';


/*  COMPONENTS  */
import {
    Title,
    Card,
    CustomTab,
    CustomGallery,
    CustomModal,
    CustomDetails,
    CustomInput,
    CustomButton,
    CustomSelect,
    CustomGroup,
    CustomIconButton,
    DateRangePicker,
    CustomTable,
    ImageCard,
    EasyDropZone
} from '../components';


/*  ICONS    */
import ManageAccountsRoundedIcon from '@mui/icons-material/ManageAccountsRounded';
import ConstructionRoundedIcon from '@mui/icons-material/ConstructionRounded';
import RoomRoundedIcon from '@mui/icons-material/RoomRounded';
import QuestionMarkRoundedIcon from '@mui/icons-material/QuestionMarkRounded';
import CollectionsRoundedIcon from '@mui/icons-material/CollectionsRounded';
import PreviewRoundedIcon from '@mui/icons-material/PreviewRounded';
import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import FileUploadRoundedIcon from '@mui/icons-material/FileUploadRounded';

import { ctxSession, ctxSettings, ctxSnackbar } from '../store';


import Axios from '../helpers/axios';
import config from '../config';


import { GoogleMap, Marker, Polygon/*, InfoWindow*/ } from '@react-google-maps/api';

/*  HELPERS */
import formDataValidator from '../helpers/formData';
import { centerCalculator, downloadCSV, CSVseparators } from '../helpers/tools';
import importer from '../helpers/importer';
import localProps from '../helpers/localProps';


import language from '../languages';
import { AxiosError, AxiosResponse } from 'axios';

import { ImageData } from '../components/CustomGallery/CustomGallery';
// TODO: Resolve View (a) ID_Piano -> Nome Piano, (b) Status -> Status Description, (c) Specifics -> Specifics Description, Comuni and Supply Types both in the grid and in the edit modal
// TODO: Clean and Type code
// TODO: Resolve back to original db fields when editing

import ExcelJS from 'exceljs';

/** INTERFACES */

interface InterventionResponse {
    id: string;
    odi?: string;
    plan_id: number;
    list_id?: number;
    ciu: number;
    supply_code?: number;
    type: string;
    status: string;
    specifics?: string;
    result?: string;
    causal?: string;
    notes?: string;
    identity_code?: number;
    accountholder?: string;
    supply_type?: string;
    municipality?: number;
    district?: string;
    address?: string;
    lat: number;
    lng: number;
    alt: number;
    old_location?: string;
    new_location?: string;
    old_serial: string;
    old_reading?: number;
    old_reading_date?: Date;
    new_reading?: number;
    new_serial?: string;
    old_brand?: string;
    new_brand?: string;
    new_digits?: number;
    seal_code?: string;
    valves?: string;
    email?: string;
    telephone?: string;
    intervention_date?: Date;
    intervention_start_date?: Date;
    intervention_end_date?: Date;
}


interface PhotoRequest {
    interventionId: number;
}


interface OriginalPhoto {
    id: number;
    category: string;
    url: string;
    log_id: number;
}

interface PhotoResponse {
    [key: string]: OriginalPhoto[];
}


interface Log {
    id: number;
    intervention_id: number;
    operator_id: number;
    log_id: number;
    status?: string;
    result?: string;
    annotations?: string;
    anomaly?: string;
    anomaly_notes?: string;
    lat?: number;
    lng?: number;
    alt?: number;
    new_brand?: string;
    new_serial?: string;
    new_digits?: number;
    old_reading_date?: Date; 
    old_reading: number;
    new_reading: number;
    seral_code?: string; 
    valves?: string;
    address?: string;
    new_location?: string;
    intervention_start_date?: Date;
    intervention_end_date?: Date; 
    created_at: Date; 
}


const InterventionManagement = () => {

    document.title = config.app.name;

    
    const session = useContext(ctxSession);
    const snackbar = useContext(ctxSnackbar);
    
    const settings = useContext(ctxSettings);
    const lang = language(settings?.data?.lang)


    const [interventionsLoading, setInterventionsLoading] = useState<boolean>(true);
    const rowsRef = useRef<any>([]);
    const [filteredRows, setFilteredRows] = useState<InterventionResponse[]>([]);
    const [selectedArea, setSelectedArea] = useState<number>(-1);
    const [selectedPlan, setSelectedPlan] = useState<number>(-1);
    const [selectedAreaPolyline, setSelectedAreaPolyline] = useState<any|null>(null);

    const [editingIntervention, setEditingIntervention] = useState<InterventionResponse | null>(null);

    const [isExportModalOpened, setIsExportModalOpened] = useState<boolean>(false);
    const [rowsExport, setRowsExport] = useState<any[]>([]);
    const columnsExportRef = useRef<any>([]);
    const [exportQueries, setExportQueries] = useState<any[]>([]);
    const [selectedExportQuery, setSelectedExportQuery] = useState<any>(null);
    const [selectedExportFormat, setSelectedExportFormat] = useState<'csv'|'xlsx'>('csv');
    const [selectedSeparator, setSelectedSeparator] = useState<CSVseparators>(';');
    const exportFormDataRef = useRef<HTMLFormElement>(null);

    useEffect(() => {
        if(isExportModalOpened){
            const onSuccess = (response:any) => {
                const {data} = response.data;
                
                setExportQueries(data);
            };
            const onError = () => { };

            Axios(session, 'get', `${config.api.internal}/InterventionManagement/exportQueries`, onSuccess, onError);

        }
        else{
            setRowsExport([]);
        }
    }, [isExportModalOpened]);


    const exportPreview = useCallback(() => {
        if(selectedExportQuery) {
            let formData = {id: selectedExportQuery.id};

            if(selectedExportQuery.parameters){
                const paramsFormData = formDataValidator(exportFormDataRef.current, 'object');
                formData = {
                    ...formData,
                    ...paramsFormData
                }
            }

            const onSuccess = (response:any) => {
                const {data} = response.data;
                // console.table(response.data.data.rows)
                setRowsExport(data.rows);
                columnsExportRef.current = data.columns;
            };
            const onError = () => { };


            Axios(session, 'post', `${config.api.internal}/InterventionManagement/export`, onSuccess, onError, formData);
        }
        // else { }
    }, [selectedExportQuery]);

    const downloadExport = useCallback(() => {
        console.log(selectedExportQuery)
        let filename = exportQueries.find((v:any) => v.id === selectedExportQuery.id)?.name;
        const date = new Date().toLocaleDateString(navigator.language, { year: 'numeric', month: '2-digit', day: '2-digit' }).replaceAll('/', '-');
        const time = new Date().toLocaleTimeString(navigator.language, { hour: '2-digit', minute: '2-digit', second: '2-digit' } ).replaceAll(':', '.');
        if(filename){
            filename = `WFM EXPORT - ${filename} (${date} ${time})`;
        }else {
            filename = `WFM EXPORT - (${date} ${time})`;
        }
        
        if(rowsExport.length) {
            switch(selectedExportFormat){
                case 'xlsx': {
                    const workbook = new ExcelJS.Workbook();
                    const worksheet = workbook.addWorksheet('WFM');


                    worksheet.columns = columnsExportRef.current.map((v:any) => {
                        return { header: v.name, key: v.name, width: 20 }
                    });
                    rowsExport.forEach(row => {
                        worksheet.addRow(row);
                    });
                    
                    // Generazione del Blob
                    workbook.xlsx.writeBuffer().then(buffer => {
                        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

                        // Creazione dell'URL temporaneo per il Blob
                        const url = window.URL.createObjectURL(blob);

                        // Creazione di un link nel DOM per il download del file
                        const a = document.createElement('a');
                        a.href = url;
                        a.download = 'nome_file.xlsx';
                        a.click();

                        // Rilascio dell'URL temporaneo dopo il download
                        window.URL.revokeObjectURL(url);
                    });
                    break;
                }
                case 'csv':
                default: {
                    downloadCSV( rowsExport, filename, selectedSeparator );
                    break;
                }
            }
            
        }
    }, [rowsExport, selectedExportQuery, exportQueries, selectedSeparator, selectedExportFormat]);


    const [center, setCenter] = useState<any>(null);
    
    // Photos
    const [photos, setPhotos] = useState<PhotoResponse | null>(null);
    const originalPhotosRef = useRef<PhotoResponse | null>(null);

    const [transformedPhotos, setTransformedPhotos] = useState<ImageData[][] | null>(null);
    

    // Logs
    const [logs, setLogs] = useState<Log[] | null>(null);
    const [selectedLog, setSelectedLog] = useState<any>(-1);


    const fetchInterventions = () => {
        setInterventionsLoading(true);

        const onSuccess = (response: any) => {
            const { data } = response.data;
            rowsRef.current = data;
            setFilteredRows(data);
            
            setInterventionsLoading(false);

            setCenter(centerCalculator(generateCoordsArray(data)));
        };

        const onError = () => {
            setInterventionsLoading(false);
        };

        Axios(session, 'get', `${config.api.internal}/InterventionManagement/Interventions`, onSuccess, onError);
    }
    

    const [options, setOptions] = useState<any>({
        interventionTypes: [],
        interventionStatuses: [],
        meterLocations: [],
        meterBrands: [],
        interventionSpecifics: [],
        meterValves: [],
        plans: [],
        areas: [],
        archiveCategories: []
    });

    useEffect(() => {
        // Options
        const onOptionsSuccess = (response: any) => {
            const { data } = response.data;
            
            const areaLS = localProps.global.get('WFMinterventionManagementArea');
            const area = Number(areaLS);
            if( areaLS && area && data.areas.find((v:any) => v.id === area) ) {
                setSelectedArea(area);
            }else{
                localProps.global.set('WFMinterventionManagementArea', -1);
                setSelectedArea(-1);
            }

            
            // const planLS = localProps.global.get('WFMinterventionManagementPlan');
            // const plan = Number(planLS);
            // if( planLS && plan && data.plans.find((v:any) => v.id === plan) ) {
            //     setSelectedPlan(area);
            // }else{
            //     localProps.global.set('WFMinterventionManagementPlan', -1);
            //     setSelectedPlan(-1);
            // }


            setOptions(data);
        };

        const onOptionsError = () => { };

        Axios(session, 'get', `${config.api.internal}/InterventionManagement/options`, onOptionsSuccess, onOptionsError);


        // Interventions
        fetchInterventions();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useEffect(() => {
        if (rowsRef.current.length && editingIntervention === null) {
            let filtered = [...rowsRef.current];

            if (selectedArea !== -1) {
                filtered = [...rowsRef.current.filter((v: any) => selectedArea === v.municipality)];
                setFilteredRows(filtered);
            }
            if (selectedPlan !== -1) {
                filtered = [...rowsRef.current.filter((v: any) => selectedPlan === v.plan_id)];
                setFilteredRows(filtered);
            }
            
            setFilteredRows(filtered);

            setCenter(centerCalculator(generateCoordsArray(filtered)));
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rowsRef.current, selectedArea, selectedPlan, editingIntervention]);

    const generateCoordsArray = (data: any) => {
        
        const tmp = data.filter((row: any) => {
            return row.lat && row.lng
        })
        .map((row: any) => {
            return {
                lat: Number(row.lat),// || Number(row.latitudineOld),
                lng: Number(row.lng)// || Number(row.longitudineOld)
            }
        });
        // console.log(tmp)
        return tmp;
    };


    const [selectedIntervention, setSelectedIntervention] = useState<InterventionResponse | null>(null);


    const printIcon = useCallback((id: number | string, status: string) => {
        // if(selectedIntervention && selectedIntervention.idVerbaleODI === id) return importer.ic.require('markerBlue.png');
        if (selectedIntervention && selectedIntervention.id === id) return importer.ic.require('markerSelected.svg');

        switch (status) {
            case 'suspended': {
                return importer.ic.require('markerBanana.png');
            }
            case 'processed': {
                return importer.ic.require('markerGreen.png');
            }
            case 'attempted_processing': {
                return importer.ic.require('markerRed.png');
            }
            case 'unprocessed': {
                return importer.ic.require('markerGray.png');
            }
        }
    }, [selectedIntervention]);


    // Tabs configuration
    const tabs = [
        {
            title: lang.gallery,
            className: 'interventionManagementPhotoGallery'
        },
        {
            title: lang.logs,
        }
    ];


    // eslint-disable-next-line react-hooks/exhaustive-deps
    const columns = useMemo<any[]>(() => {

        if(!options.meterValves.length || !options.areas.length) return [];

        let columns = [
            { name: 'id', displayName: <>{lang.id}</>, resizable: true, width: 70 },
            { name: 'plan_id', resizable: true, width: 100, hide: true},
            { name: 'list_id', resizable: true, width: 100, hide: true },
            { name: 'odi', displayName: lang.odi, resizable: true, width: 150 },
            { name: 'type', displayName: lang.type, resizable: true, width: 150,
                render: (row:InterventionResponse) => lang[row.type],
                searchInRender: true,
                dynamicClass: (row:InterventionResponse) => row.type,
            },  
            { name: 'accountholder', displayName: lang.accountholder, resizable: true, width: 150 },
            {
                name: 'status',
                displayName: lang.status,
                resizable: true,
                render: (row:InterventionResponse) => lang[row.status],
                searchInRender: true,
                dynamicClass: (row:InterventionResponse) => {
                    switch (row.status) {
                        case 'processed': return 'processed';
                        case 'suspend': return 'suspended';
                        case 'attempted_processing': return 'attempted_processing';
                        default:
                        case 'unprocessed': return 'unprocessed';
                    }
                }, width: 150
            }, 
            {
                name: 'municipality',
                displayName: lang.towns,
                resizable: true,
                width: 120,
                render: (row:InterventionResponse) => options.areas.length ? (options.areas.find((v:any) => v.id === row.municipality )?.name ?? row.municipality) : null,
                searchInRender: true
            },
            { name: 'district', displayName: lang.district, resizable: true, width: 150 },
            { name: 'old_location', displayName: lang.old_location, resizable: true, width: 150 },
            { name: 'new_location', displayName: lang.new_location, resizable: true, width: 150 },
            { name: 'old_serial', displayName: lang.old_serial, resizable: true, width: 150 },
            { name: 'new_serial', displayName: lang.new_serial, resizable: true, width: 150 },

            { name: 'ciu', displayName: lang.ciu, resizable: true, width: 150, style:{fontWeight: 'bold'} },
            { name: 'supply_code', displayName: lang.supply_code, resizable: true, width: 150 },         
            { name: 'specifics', displayName: lang.specifics, resizable: true, width: 150 },
            { name: 'result', displayName: lang.result, resizable: true, width: 150 },
            { name: 'causal', displayName: lang.causal, resizable: true, width: 150 },
            { name: 'notes', displayName: lang.notes, resizable: true, width: 150 },
            { name: 'identity_code', displayName: lang.identity_code, resizable: true, width: 150 },
            { name: 'supply_type', displayName: lang.supply_type, resizable: true, width: 150 },
            { name: 'address', displayName: lang.address, resizable: true, width: 150 },
            { name: 'lat', displayName: lang.latitude, resizable: true, width: 150 },
            { name: 'lng', displayName: lang.longitude, resizable: true, width: 150 },
            { name: 'alt', displayName: lang.altitude, resizable: true, width: 150 },
            { name: 'old_reading', displayName: lang.old_reading, resizable: true, width: 150 },
            {
                name: 'old_reading_date',
                displayName: lang.old_reading_date,
                resizable: true,
                width: 150,
                render: (row:InterventionResponse) => row.old_reading_date ? new Date(row.old_reading_date).toLocaleString(navigator.language, { hour: '2-digit', minute: '2-digit', second: '2-digit', year: 'numeric', month: '2-digit', day: '2-digit' } ) : null,
                searchInRender: true,
            },

            { name: 'new_reading', displayName: lang.new_reading, resizable: true, width: 150 },
            { name: 'old_brand', displayName: lang.old_brand, resizable: true, width: 150 },
            { name: 'new_brand', displayName: lang.new_brand, resizable: true, width: 150 },
            { name: 'new_digits', displayName: lang.digits_number, resizable: true, width: 150 },
            { name: 'seal_code', displayName: lang.seal_code, resizable: true, width: 150 },
            { name: 'valves', displayName: lang.valve, resizable: true, width: 150, render: (row:InterventionResponse) => row.valves ? ( lang[row.valves] ?? options.meterValves.find((v:any) => v.code === row.valves ?? '' )?.label ) : null,
            searchInRender: true },
            { name: 'email', displayName: lang.email, resizable: true, width: 150 },
            { name: 'telephone', displayName: lang.telephone, resizable: true, width: 150 },
            {
                name: 'intervention_date',
                displayName: lang.intervention_date,
                resizable: true,
                width: 150,
                render: (row:InterventionResponse) => row.intervention_date ? new Date(row.intervention_date).toLocaleString(navigator.language, { hour: '2-digit', minute: '2-digit', second: '2-digit', year: 'numeric', month: '2-digit', day: '2-digit' } ) : null,
                searchInRender: true,
            },
            {
                name: 'intervention_start_date',
                displayName: lang.intervention_start_date,
                resizable: true,
                width: 150,
                render: (row:InterventionResponse) => row.intervention_start_date ? new Date(row.intervention_start_date).toLocaleString(navigator.language, { hour: '2-digit', minute: '2-digit', second: '2-digit', year: 'numeric', month: '2-digit', day: '2-digit' } ) : null,
                searchInRender: true,
            },
            {
                name: 'intervention_end_date',
                displayName: lang.intervention_end_date,
                resizable: true,
                width: 150, 
                render: (row:InterventionResponse) => row.intervention_end_date ? new Date(row.intervention_end_date).toLocaleString(navigator.language, { hour: '2-digit', minute: '2-digit', second: '2-digit', year: 'numeric', month: '2-digit', day: '2-digit' } ) : null,
                searchInRender: true,
            }
        ];
            

        if(session?.data?.userData?.role !== 'viewer') {
            return [
                {
                    name: 'action',
                    displayName: '',
                    width: 30,
                    filtering: false,
                    disableSort: true,
                    draggable: false,
                    render: (row: InterventionResponse) => {
                        return <CustomIconButton
                            onClick={() => {
                                const record:InterventionResponse = rowsRef.current.find((v: InterventionResponse, k: number) => v.id === row.id);
                                
                                setEditingIntervention(record);
                            }}
                        >
                            <EditRoundedIcon />
                        </CustomIconButton>
                    },
                    preventSelection: true
                },
                ...columns
            ]
        }

        return columns;

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settings, lang, options]);

    const [selectedTab, setSelectedTab] = useState<number|null>(0);
    const columns_log = useMemo<any[]>( () => {
        if(!options.meterValves.length || !options.operators.length) return [];

        return [
            {
                type: 'action',
                filtering: false,
                render: (row:any) => 
                    <CustomIconButton
                        onClick={() => {
                            setSelectedLog(row.id);
                            setSelectedTab(0);
                        }}
                    >
                        <CollectionsRoundedIcon />
                    </CustomIconButton>
                ,
                width: 40
            },
            { name: 'id', displayName: 'ID', resizable: true, width: 80 },
            { name: 'operator_id', displayName: lang.operator, resizable: true, hide: true, width: 150 },
            { name: 'operator', displayName: lang.operator, resizable: true, width: 150, render: (row:any) => options.operators?.find((v:any) => v.id === row.operator_id)?.fullname ?? '' },
            {
                name: 'status',
                displayName: lang.status,
                resizable: true,
                render: (row:InterventionResponse) => lang[row.status],
                searchInRender: true,
                dynamicClass: (row:InterventionResponse) => {
                    switch (row.status) {
                        case 'processed': return 'processed';
                        case 'suspend': return 'suspended';
                        case 'attempted_processing': return 'attempted_processing';
                        default:
                        case 'unprocessed': return 'unprocessed';
                    }
                }, width: 150
            },
            { name: 'result', displayName: lang.result, resizable: true, width: 150 },
            { name: 'annotations', displayName: lang.notes, resizable: true, width: 150 },
            { name: 'anomaly', displayName: lang.anomaly, resizable: true, width: 150 },
            { name: 'anomaly_notes', displayName: lang.anomaly_notes, resizable: true, width: 150 },
            { name: 'lat', displayName: lang.latitude, resizable: true, width: 150 },
            { name: 'lng', displayName: lang.longitude, resizable: true, width: 150 },
            { name: 'alt', displayName: lang.altitude, resizable: true, width: 150 },
            { name: 'new_brand', displayName: lang.new_brand, resizable: true, width: 150 },
            { name: 'new_serial', displayName: lang.new_serial, resizable: true, width: 150 },
            { name: 'new_digits', displayName: lang.digits_number, resizable: true, width: 150 },
            { name: 'old_reading_date', displayName: lang.old_reading_date, resizable: true, width: 150,
            render: (row:InterventionResponse) => row.old_reading_date ? new Date(row.old_reading_date).toLocaleString() : null },
            { name: 'old_reading', displayName: lang.old_reading, resizable: true, width: 150 },
            { name: 'new_reading', displayName: lang.new_reading, resizable: true, width: 150 },
            { name: 'seal_code', displayName: lang.seal_code, resizable: true, width: 150 },
            { name: 'valves', displayName: lang.valve, resizable: true, width: 150, render: (row:InterventionResponse) => row.valves ? ( lang[row.valves] ?? options.meterValves.find((v:any) => v.code === row.valves )?.label ?? '' ) : null,
            searchInRender: true },
            { name: 'address', displayName: lang.address, resizable: true, width: 150 },
            { name: 'new_location', displayName: lang.new_location, resizable: true, width: 150 },
            { name: 'intervention_start_date', displayName: lang.intervention_start_date, resizable: true, width: 150,
            render: (row:InterventionResponse) => row.intervention_start_date ? new Date(row.intervention_start_date).toLocaleString() : null },
            { name: 'intervention_end_date', displayName: lang.intervention_end_date, resizable: true, width: 150,
            render: (row:InterventionResponse) => row.intervention_end_date ? new Date(row.intervention_end_date).toLocaleString() : null }
        ]
    }, [lang, options]);


    useEffect(() => {
        if (selectedIntervention && selectedIntervention.lat && selectedIntervention.lng) {
            setCenter({
                lat: selectedIntervention.lat,
                lng: selectedIntervention.lng
            });
        }
        else if (rowsRef.current.length) {
            setCenter(centerCalculator(generateCoordsArray(rowsRef.current)));
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedIntervention, rowsRef.current]);


    // const formRef = useRef<HTMLFormElement>(null);
    const submitRef = useRef<HTMLButtonElement>(null);

    const submitForm = () => {
        if (submitRef && submitRef.current) {
            submitRef.current.click();
        }
    }

    const onSubmitInterventionEdit = useCallback( (e: any) => {
        e.preventDefault();

        const formData: InterventionResponse = formDataValidator(e.target, 'object') as InterventionResponse;

        const onSuccess = (response: any) => {
            const row = response.data.data;
            if(row){
                const index = rowsRef.current.findIndex((v:InterventionResponse, k:number) => v.id === row.id );
                rowsRef.current[index] = row;
                // console.log(rowsRef.current)

                
                setEditingIntervention(null);
                
                snackbar?.set({message: lang.successfully_updated(), severity: 'success'})
            }
        };

        const onError = (response:any) => {
            response.response.data.errors.forEach((v:any, k:number) => {
                if(v.message === 'failed_update'){
                    snackbar?.set({message: lang['faiPhotoRequestled_update_no_changes'], severity: 'warning'});
                }else{
                    snackbar?.set({message: lang[v.message], severity: 'error'});
                }
            })
        };

        Axios(session, 'patch', `${config.api.internal}/InterventionManagement/Interventions`, onSuccess, onError, formData);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rowsRef.current, snackbar]);


    const formatPhotos = (log_id: number | null = null) => {
        if(!originalPhotosRef.current) return [];
        const data = originalPhotosRef.current;
        const tmp = Object.keys(originalPhotosRef.current).map( (category: string) => data[category].filter(image => log_id === null ? true : (image.log_id === log_id))
                                                                                            .map(image => {
                                                                                                            return {
                                                                                                                id: image.id,
                                                                                                                title: lang[category] ?? category,
                                                                                                                src: image.url,
                                                                                                                category
                                                                                                            }
                                                                                                })
                                                        );

        if(tmp.every(v => v.length === 0)) return [];

        return tmp.filter(v => v.length > 0);   //removes empty collections
    }
    

    /** PHOTO LOADING */
    const loadInterventionPhotos = (interventionId:number) => {
        /// Early return
        if(!interventionId) return;

        const onSuccess = (response: AxiosResponse) => {
            const data : PhotoResponse  = response.data.data;
            originalPhotosRef.current = data;
            setPhotos(data);

            const transformedPhotos : ImageData[][] = formatPhotos();

            setTransformedPhotos(transformedPhotos);
        };
        
        const onError = (response: AxiosError) => {
            console.error('Error loading photos:', response.message);
            // snackbar?.set({message: response.message, severity: 'error'});   // no photos
            setPhotos(null);
            setTransformedPhotos(null);
        };  
        
        Axios(session, 'get', `${config.api.internal}/InterventionManagement/Photo/${interventionId}`, onSuccess, onError);
    };

    useEffect(() => {
        /// Early return
        if(!selectedIntervention) return;
        loadInterventionPhotos(Number(selectedIntervention.id));
    }, [selectedIntervention]);

    /** PHOTO MANAGEMENT */
    const [isPhotoManagementModalOpened, setIsPhotoManagementModalOpened] = useState<boolean>(false);
    const deletingPhotoRef = useRef<any|null>(null);
    const [isDeletePhotoModalOpened, setIsDeletePhotoModalOpened] = useState<boolean>(false);

    const deletePhoto = useCallback(() => {
        if(!deletingPhotoRef.current || !originalPhotosRef.current) return;

        const formData =  {
            id: deletingPhotoRef.current.id,
        };
        
        const onSuccess = () => {   //response:any
            if(originalPhotosRef.current) {
                let tmp = originalPhotosRef.current;
                const tmpCategory = originalPhotosRef.current[deletingPhotoRef.current.category].filter((v:any) => v.id !== formData.id );
                tmp[deletingPhotoRef.current.category] = tmpCategory;
                originalPhotosRef.current = tmp;
            }
            const tmp = formatPhotos(selectedLog === -1 ? null : selectedLog);
            setTransformedPhotos(tmp);
            setIsDeletePhotoModalOpened(false);
        };
        const onError = () => {
            setIsDeletePhotoModalOpened(false);
        };

        Axios(session, 'delete', `${config.api.internal}/InterventionManagement/Photo`, onSuccess, onError, formData);
    }, [selectedLog]);

    const [uploadedPhoto, setUploadedPhoto] = useState<any>({
        file: undefined,
        dataURL: undefined,
        category: undefined,
    });

    const onSubmitUploadPhoto = useCallback((e:any) => {
        e.preventDefault()

        /// Early return
        if(!selectedIntervention) return;

        let formData : any = {
            id: selectedIntervention.id,
            category: uploadedPhoto.category,
            file: uploadedPhoto.file
        };
        
        /// Early return
        if(!formData.category) {
            snackbar?.set({message: `${lang.select_a_category}!`, severity: 'warning'});
            return;
        }

        
        const onSuccess = (response: any) => {
            const data : any = response.data.data;
            
            if(!originalPhotosRef.current){
                let tmp : any = {};
                tmp[data.category] = [data];
                originalPhotosRef.current = tmp;
            }else{
                originalPhotosRef.current[data.category].push(data);
            }

            setUploadedPhoto({
                file: undefined,
                dataURL: undefined,
                category: undefined
            });
        };

        const onError = (response:any) => {
            const {data}  = response.response;
            const {message} = data.errors[0];
            snackbar?.set({message: lang[message] ?? message, severity: 'error'});
        };

        Axios(session, 'post', `${config.api.internal}/InterventionManagement/Photo`, onSuccess, onError, formData);

    }, [isPhotoManagementModalOpened, snackbar, uploadedPhoto, selectedIntervention]);


    /** LOGS LOADING */

    const loadInterventiongLogs = (interventionId:number) => {

        const onSuccess = (response: AxiosResponse) => {
            setLogs( response.data.data );

        };
        
        const onError = (response: AxiosError) => {
            console.error('Error loading logs:', response.message);
            // snackbar?.set({message: response.message, severity: 'error'});   // no logs
        };
        
        Axios(session, 'post', `${config.api.internal}/InterventionManagement/Logs`, onSuccess, onError, {interventionId: interventionId});
            
    };


    // Function to update the gallery based on the selected log
    const updateGalleryForSelectedLog = (selectedLogId: number | null) => {
        
        if (!photos) {
            setTransformedPhotos(null);
            return;
        }
        
        if (selectedLogId === -1) {
            setTransformedPhotos(formatPhotos());
            return;
        }

        const selectedLog = logs?.find(log => log.id === selectedLogId);
        if (!selectedLog) {
            setTransformedPhotos(null);
            return;
        }

        if(originalPhotosRef.current){
            const filteredPhotos = formatPhotos(selectedLogId);
            setTransformedPhotos(filteredPhotos);
        }
    };

    // Call the updateGalleryForSelectedLog function when a log is selected
    useEffect(() => {
        updateGalleryForSelectedLog(selectedLog);

        if(selectedLog === -1){
            if (!originalPhotosRef.current || !Array.isArray(originalPhotosRef.current)) {
                return;
            }
        }
    }, [selectedLog]);



    return (
        <Fade in={true} timeout={{enter: 400}} >
            <div id="InterventionManagement">
                <main>
                    <div className='head'>

                        <Title
                            size="xl"
                        >
                            {lang.work_force_management}
                        </Title>

                        <CustomGroup variant="separated" >
                            <CustomSelect
                                name="id"
                                value={selectedPlan}
                                options={[{
                                        value: -1,
                                        label: lang.all_plans,
                                        data: null
                                    }, 
                                    ...options.plans.map((v:any) => ({
                                        value: v.id,
                                        label: `${v.name} (${new Date(v.created_at).toLocaleDateString()})`,
                                    }))
                                ]}
                                onChange={(value: any) => {
                                    setSelectedPlan(value);
                                }}
                            />
                            <CustomSelect
                                icon={<LocationCityIcon />}
                                name="id"
                                value={selectedArea}
                                options={[{
                                    value: -1,
                                    label: lang.all_towns,
                                    data: null
                                }, ...options.areas.map((v:any) => {
                                    return {
                                        value: v.id,
                                        label: v.name,
                                        data: v.geoArea
                                    }
                                })]}
                                onChange={(value: any, data: any, selectedOption: any, ref: any) => {
                                    localProps.global.set('WFMinterventionManagementArea', value);
                                    setSelectedArea(value);
                                    setSelectedAreaPolyline(data);
                                }}
                            />
                            
                            <CustomButton
                                nospam={true}
                                onClick={fetchInterventions}
                            >
                                <RefreshRoundedIcon />
                            </CustomButton>
                            
                            <CustomButton
                                variant="info"
                                nospam={true}
                                onClick={() => { setIsExportModalOpened(true) }}
                            >
                                <DownloadRoundedIcon />
                            </CustomButton>
                        </CustomGroup>
                        
                    </div>



                    <Card class="table">
                        <CustomTable
                            loading={interventionsLoading}
                            // tableName={"Interventions"}
                            columns={columns}
                            stripped={true}

                            perPage={50}
                            rows={filteredRows}

                            quickFilter={true}
                            selectiveQuickFilter={true}
                            columnFilter={true}


                            textOverflow='ellipsis'
                            multiSort={true}
                            // sortLimit={2}
                            draggable={true}
                            toggleColumns={true}
                            switchDensity={true}


                            selectedRows={selectedIntervention ? [selectedIntervention.id] : null}
                            selectRows={true}
                            onRowClick={(v: InterventionResponse, r: any) => {
                                setLogs(null);
                                setPhotos(null);
                                setSelectedIntervention(null);
                                originalPhotosRef.current = null;
                                setTransformedPhotos(null);
                                setSelectedLog(-1);
                                if (!selectedIntervention || (selectedIntervention && v.id !== selectedIntervention.id)) {
                                    setSelectedIntervention(v);
                                    // Load logs
                                    loadInterventiongLogs(Number(v.id));

                                    return;
                                }
                            } }
                            // multiSelect={true}
                            // showMultiSelectColumn={true}
                            // onSelectionChange={(v: any) => {console.log(v)}}
                        />
                    </Card>

                    <Card class="map">
                        <GoogleMap
                            mapContainerStyle={{ width: '100%', height: '100%', borderRadius: "var(--border-radius-xl)" }}
                            center={center || config.env.map.defaultCenter}
                            zoom={selectedIntervention ? 13 : 12}
                            options={{ minZoom: 3, fullscreenControl: true }}
                            onLoad={(map) => {
                                map.setMapTypeId("satellite");
                                // map.setTilt(45); // map.setHeading(90)
                            }}
                        >
                            {filteredRows.length &&
                                filteredRows.map((marker: any, k: number) =>
                                    <Marker
                                        icon={printIcon(marker.id, marker.status)}
                                        key={k}
                                        position={{ lat: marker.lat, lng: marker.lng }}
                                        onClick={() => {
                                            if (!selectedIntervention || (selectedIntervention && marker.id !== selectedIntervention.id)) {
                                                setSelectedIntervention(marker);
                                            } else {
                                                setSelectedIntervention(null);
                                            }
                                        }}
                                        // opacity={selectedIntervention === null ? 1 : (selectedIntervention.id === marker.id ? 1 : .2) }
                                        zIndex={selectedIntervention && selectedIntervention.id === marker.id ? 2 : 1}
                                    />
                                )
                            }

                            { selectedAreaPolyline &&
                                <Polygon
                                    paths={selectedAreaPolyline}
                                    options={{
                                        fillColor: 'transparent',
                                        strokeColor: 'red',
                                        strokeOpacity: .5,
                                    }}
                                />
                            }
                        </GoogleMap>
                    </Card>

                    <Card class='explorer'>
                        <CustomTab
                            tabs={tabs}
                            selectedTab={selectedTab}
                            onChange={() => {
                                setSelectedTab(null);
                            }}
                        >     
                            { transformedPhotos &&
                            <>
                                <header>
                                    <div>
                                    <CustomSelect
                                        name="id"
                                        class="mb-2"
                                        size={'sm'}
                                        options={[
                                            {
                                                value: -1,
                                                label: lang.all_photos
                                            }, 
                                            ...(logs ? logs.map((log: Log) => ({
                                                value: log.id,
                                                label: `Log ID: ${log.id}`
                                            })) : [])
                                        ]}
                                        value={selectedLog}
                                        onChange={(value: any) => {
                                            setSelectedLog(value)
                                        }}
                                    />
                                    { selectedLog !== -1 &&
                                        <CustomIconButton
                                            // size={'sm'}
                                            onClick={() => {
                                                setSelectedTab(1)
                                            }}
                                        >
                                            <PreviewRoundedIcon />
                                        </CustomIconButton>
                                    }
                                    </div>
                                    <div>
                                        <CustomButton
                                            size="sm"
                                            onClick={() => {
                                                setIsPhotoManagementModalOpened(true);
                                            }}
                                        >
                                            <EditRoundedIcon />
                                            {lang.edit}
                                        </CustomButton>
                                    </div>
                                </header>
                                <CustomGallery 
                                    images={transformedPhotos}
                                    slotsPerPage={4} 
                                />
                            </>
                            }
                            { logs &&
                                <CustomTable
                                    columns={columns_log}
                                    stripped={true}

                                    perPage={50}
                                    rows={logs}

                                    quickFilter={true}
                                    selectiveQuickFilter={true}
                                    columnFilter={true}

                                    textOverflow='ellipsis'
                                    multiSort={true}
                                    
                                    draggable={true}
                                    toggleColumns={true}
                                    switchDensity={true}

                                    selectRows={true}
                                    selectedRows={selectedLog !== -1 ? [selectedLog] : []}
                                />   
                            }                    
                         </CustomTab>
                    </Card>

                </main>

                { editingIntervention &&
                    <CustomModal
                        class="interventionEditModal"
                        title={lang.edit_intervention}
                        isOpen={editingIntervention}
                        onClose={() => {
                            setEditingIntervention(null);
                        }}
                        closeButton={true}
                        scrollableBody={true}
                        footer={
                            <CustomButton onClick={submitForm}>{lang.save}</CustomButton>
                        }
                    >
                        {/* {
                        <Title class="mb-3">{lang.plan}: <i>{`${options.plans.find((v:any) => v.id === editingIntervention.plan_id)?.name} (${new Date(options.plans.find((v:any) => v.id === editingIntervention.plan_id)?.created_at).toLocaleDateString()})`}</i></Title>
                        } */}
                        <form /*ref={formRef}*/ onSubmit={onSubmitInterventionEdit} className='mb-2'>
                            <input name="id" value={editingIntervention.id} type="hidden" />
                            
                            <CustomSelect
                                name="plan_id"
                                label={lang.plan}
                                value={editingIntervention.plan_id}
                                options={ options.plans.map((v:any) => ({
                                            value: v.id,
                                            label: `${v.name} (${new Date(v.created_at).toLocaleDateString()})`,
                                        }))
                                }
                            />
                            

                            <fieldset>
                                <legend><ManageAccountsRoundedIcon /> {lang.registry_details}</legend>
                                <CustomInput name="identity_code" label={lang.identity_code} value={editingIntervention?.identity_code} pattern="[0-9]+" />
                                <CustomInput name="accountholder" label={lang.accountholder} value={editingIntervention?.accountholder} />
                                <CustomInput name="email" type="email" label={lang.email} value={editingIntervention?.email} />
                                <CustomInput name="telephone" type="tel" label={lang.telephone} value={editingIntervention?.telephone} />
                            </fieldset>

                            <fieldset>
                                <legend><QuestionMarkRoundedIcon /> {lang.miscellaneous}</legend>
                                <CustomInput name="causal" label={lang.causal} value={editingIntervention?.causal} />
                                <CustomInput name="notes" label={lang.notes} value={editingIntervention?.notes} />
                            </fieldset>


                            <fieldset>
                                <legend><RoomRoundedIcon /> {lang.geo_data}</legend>
                                
                                <CustomSelect
                                    label={lang.municipality}
                                    name="municipality"
                                    value={editingIntervention?.municipality}
                                    options={[...options.areas.map((v:any) => {
                                        return {
                                            value: v.id,
                                            label: v.name,
                                            data: v.geoArea
                                        }
                                    })]}
                                />
                                <CustomInput name="district" label={lang.district} value={editingIntervention?.district} />
                                <CustomInput pattern="\d+|\d+\.\d{1,10}" name="lat" label={lang.latitude} value={editingIntervention?.lat} />
                                <CustomInput pattern="\d+|\d+\.\d{1,10}" name="lng" label={lang.longitude} value={editingIntervention?.lng} />
                                <CustomInput pattern="\d+|\d+\.\d{1,10}" name="alt" label={lang.altitude} value={editingIntervention?.alt} />
                                <CustomInput name="address" label={lang.address} value={editingIntervention?.address} />
                                
                                <CustomSelect
                                    style={{width: 250, maxWidth: 250}}
                                    label={lang.old_location}
                                    name="old_location"
                                    value={editingIntervention?.old_location}
                                    options={[...options.meterLocations.map((_v:string|number) => {
                                        return {
                                            value: _v,
                                            label: _v
                                        }
                                     })]}
                                />
                                <CustomSelect
                                    style={{width: 250, maxWidth: 250}}
                                    label={lang.new_location}
                                    // variant="cloud"
                                    name="new_location"
                                    value={editingIntervention?.new_location}
                                    options={[...options.meterLocations.map((_v:string|number) => {
                                        return {
                                            value: _v,
                                            label: _v
                                        }
                                     })]}
                                />
                            </fieldset>


                            <fieldset>
                                <legend><ConstructionRoundedIcon /> {lang.intervention}</legend>
                                <CustomInput name="odi" label="ODI" value={editingIntervention?.odi} />
                                <CustomInput name="ciu" label={lang.ciu} value={editingIntervention?.ciu} disabled={true} />
                                {
                                    editingIntervention.supply_code
                                    ?
                                    <>
                                    <CustomInput name="supply_code" label={lang.supply_code} value={editingIntervention?.supply_code} pattern="[0-9]+" />
                                    <span></span>
                                    </>
                                    :
                                    <></>
                                }

                                <CustomInput name="type" label={lang.intervention_type} value={lang[editingIntervention?.type]}  disabled={true} />
                                
                                <CustomSelect
                                    style={{width: 250, maxWidth: 250}}
                                    label={lang.intervention_specifics}
                                    name="specifics"
                                    value={editingIntervention?.specifics}
                                    options={[...options.interventionSpecifics.map((_v:string|number) => {
                                        return {
                                            value: _v,
                                            label: _v
                                        }
                                     })]}
                                />

                                {
                                    editingIntervention.status && !options.interventionStatuses.includes(editingIntervention.status)
                                    ?
                                    <CustomInput name="type" label={lang.intervention_status} value={lang[editingIntervention?.status]}  disabled={true} />
                                    :
                                    <CustomSelect
                                        style={{width: 250, maxWidth: 250}}
                                        label={lang.intervention_status}
                                        name="status"
                                        value={editingIntervention.status}
                                        options={[...options.interventionStatuses.map((_v:string|number) => {
                                            return {
                                                value: _v,
                                                label: lang[_v]
                                            }
                                        })]}
                                    />
                                }
                                
                                <CustomSelect
                                    style={{width: 250, maxWidth: 250}}
                                    label={lang.intervention_result}
                                    name="result"
                                    value={editingIntervention?.result}
                                    options={[...options.interventionSpecifics.map((_v:string|number) => {
                                        return {
                                            value: _v,
                                            label: _v
                                        }
                                     })]}
                                />
                                
                                <CustomSelect
                                    style={{width: 250, maxWidth: 250}}
                                    label={lang.old_brand}
                                    name="old_brand"
                                    value={editingIntervention?.old_brand}
                                    options={[...options.meterBrands.map((_v:string|number) => {
                                        return {
                                            value: _v,
                                            label: _v
                                        }
                                     })]}
                                />
                                
                                <CustomSelect
                                    style={{width: 250, maxWidth: 250}}
                                    label={lang.new_brand}
                                    name="new_brand"
                                    value={editingIntervention?.new_brand}
                                    options={[...options.meterBrands.map((_v:string|number) => {
                                        return {
                                            value: _v,
                                            label: _v
                                        }
                                     })]}
                                />
                                <CustomInput name="old_serial" label={lang.old_serial} value={editingIntervention?.old_serial} />
                                <CustomInput name="new_serial" label={lang.new_serial} value={editingIntervention?.new_serial} />
                                <CustomInput name="old_reading" type="number" label={lang.old_reading} value={editingIntervention?.old_reading} />
                                <CustomInput name="old_reading_date" label={lang.old_reading_date} value={editingIntervention?.old_reading_date ? new Date(editingIntervention.old_reading_date).toLocaleString() : ''}  disabled={true}/>
                                <CustomInput name="new_reading" type="number" label={lang.new_reading} value={editingIntervention?.new_reading} />
                                <CustomInput name="new_digits" type="number" label={lang.digits_number} value={editingIntervention?.new_digits} />
                                <CustomInput name="seal_code" label={lang.seal_code} value={editingIntervention?.new_digits} />
                                
                                <CustomSelect
                                    style={{ width: 250, maxWidth: 250 }}
                                    label={lang.valve}
                                    variant="classic"
                                    name="valves"
                                    value={editingIntervention?.valves}
                                    options={[...options.meterValves.map((_v:any|number) => {
                                        return {
                                            value: _v.code,
                                            label: lang[_v.code] ?? _v.label
                                        }
                                     })
                                    ]}
                                    placement={'top'}
                                />
            
                            </fieldset>


                            <button ref={submitRef} style={{ display: 'none' }}></button>
                        </form>
                    </CustomModal>
                }

                { isExportModalOpened &&
                    <CustomModal
                        class="wfmExportModal"
                        title={lang.exportation}
                        isOpen={isExportModalOpened}
                        onClose={() => {
                            setIsExportModalOpened(false);
                            
                            setSelectedExportQuery(null);

                            setRowsExport([]);
                            columnsExportRef.current = [];
                        }}
                        closeButton={true}
                    >
                        <header>
                            <CustomSelect
                                style={{width: 200}}
                                placeholder={lang.select_exportation}
                                value={selectedExportQuery?.id}
                                options={exportQueries.map((v:any) => {
                                        return {
                                            value: v.id,
                                            label: v.name,
                                            data: v.parameters
                                        }
                                    })}
                                onChange={(v:any, data:any) => {
                                    setSelectedExportQuery({
                                        id: v,
                                        parameters: data
                                    });
                                }}
                            />
                            
                            <CustomButton
                                onClick={exportPreview}
                            >
                                <PreviewRoundedIcon />
                                {lang.preview}
                            </CustomButton>

                            { rowsExport.length > 0 &&
                                <>
                                    <CustomButton
                                        variant="info"
                                        onClick={downloadExport}
                                    >
                                        <DownloadRoundedIcon />
                                        {lang.export}
                                    </CustomButton>
                                    <CustomSelect
                                        style={{width: 200}}
                                        value={selectedExportFormat}
                                        label={lang.format}
                                        options={[
                                            {
                                                value: 'csv',
                                                label: 'CSV (.csv)'
                                            },
                                            {
                                                value: 'xlsx',
                                                label: 'Excel (.xlsx)'
                                            }
                                        ]}
                                        onChange={(v:any) => {
                                            setSelectedExportFormat(v);
                                        }}
                                    />

                                    { selectedExportFormat === 'csv' ?
                                        <CustomSelect
                                            style={{width: 200}}
                                            value={selectedSeparator}
                                            label={lang.separator}
                                            options={[
                                                {
                                                    value: ';',
                                                    label: ';'
                                                },
                                                {
                                                    value: ',',
                                                    label: ','
                                                },
                                                {
                                                    value: '\t',
                                                    label: 'Tab'
                                                }
                                            ]}
                                            onChange={(v:any) => {
                                                setSelectedSeparator(v);
                                            }}
                                        />
                                        :
                                        <></>
                                    }
                                    
                                </>
                            }
                        </header>
                        
                        <form ref={exportFormDataRef}>
                            { selectedExportQuery?.parameters?.length > 0 ?
                                
                                <CustomDetails
                                    variant="add-on"
                                    isOpen={true}
                                    title={lang.parameters}
                                >
                                    <fieldset>
                                        {
                                        selectedExportQuery.parameters.map((v:string) => {
                                            const paramName = v.replaceAll('@', '');
                                            if( v.toLowerCase().endsWith('_date') ){
                                                return <DateRangePicker
                                                            label={lang[paramName] ?? paramName}
                                                            singleDay={true}
                                                            name={v}
                                                        />
                                            }
                                            else{
                                                return <CustomInput
                                                            style={{marginBottom: 0}}
                                                            name={v}
                                                            label={lang[paramName] ?? paramName}
                                                        />
                                            }
                                        })
                                        }
                                    </fieldset>
                                </CustomDetails>
                                : <></>
                            }
                        </form>

                        <CustomTable
                            quickFilter={true}
                            perPage={10}
                            stripped={true}
                            columns={columnsExportRef.current}
                            rows={rowsExport}
                        />
                    </CustomModal>
                }

                
                { isPhotoManagementModalOpened &&
                    <CustomModal
                        class="wfmPhotoManagementModal"
                        title={lang.photo}
                        isOpen={isPhotoManagementModalOpened}
                        onClose={() => {
                            setIsPhotoManagementModalOpened(false);
                            
                            setUploadedPhoto({
                                file: undefined,
                                dataURL: undefined,
                                category: undefined
                            });
                        }}
                        closeButton={true}
                        scrollableBody={true}
                    >
                        
                        <CustomDetails
                            class='uploadPhoto mb-2'
                            title={lang.import}
                            variant="add-on"
                        >
                            <form onSubmit={onSubmitUploadPhoto}>
                                <EasyDropZone accept=".jpeg, .jpg, .png"
                                    onChange={(files:File[]) => {
                                        var reader = new FileReader();
        
                                        reader.onload = function(event:any) {
                                            // Una volta caricato il file, puoi ottenere il data URL dall'evento
                                            var dataURL = event.target.result;
                                            // console.log(dataURL); // Qui puoi fare qualcosa con il data URL
                                            setUploadedPhoto({
                                                ...uploadedPhoto,
                                                file: files[0],
                                                dataURL
                                            });
                                        };
                                        
                                        // Leggi il file come data URL
                                        reader.readAsDataURL(files[0]);
                                    }}
                                />
                                <div className='photoPreview'>
                                    <ImageCard
                                        variant="polaroid"
                                        src={uploadedPhoto.dataURL}
                                        alt={lang.preview}
                                        title={lang.preview}
                                    />
                                    <CustomSelect
                                        placeholder={lang.select_a_category}
                                        name={'category'}
                                        size="sm"
                                        options={options.archiveCategories.length ? options.archiveCategories.map((v:any) => ({
                                            value: v,
                                            label: lang[v] ?? v
                                        })) : []}
                                        value={uploadedPhoto.category}
                                        onChange={(v) => {
                                            setUploadedPhoto({
                                                ...uploadedPhoto,
                                                category: v
                                            });
                                            
                                        }}
                                        required={true}
                                    />
                                    <CustomGroup variant="separated" >
                                        { uploadedPhoto.dataURL !== undefined &&
                                            <CustomButton variant='danger'
                                                size="sm"
                                                onClick={() => {
                                                    setUploadedPhoto({
                                                        file: undefined,
                                                        dataURL: undefined,
                                                        category: undefined
                                                    });
                                                }}
                                                disabled={uploadedPhoto.dataURL === undefined ? true : false}
                                            >
                                                <DeleteForeverIcon />
                                            </CustomButton>
                                        }
                                        <CustomButton
                                            class='w-100'
                                            size="sm"
                                            type="submit"
                                            disabled={uploadedPhoto.dataURL === undefined ? true : false}
                                        >
                                            <FileUploadRoundedIcon /> {lang.import}
                                        </CustomButton>
                                    </CustomGroup>
                                </div>
                            </form>
                        </CustomDetails>

                        
                        { originalPhotosRef.current !== null && selectedIntervention && uploadedPhoto ? //uploadedPhoto required to refresh the print
                            Object.keys(originalPhotosRef.current).map((v:string) =>
                                !originalPhotosRef.current ? <></> /// Early return
                                :
                                <CustomDetails
                                    class='mb-2'
                                    title={lang[v] ?? v}
                                    variant="section"
                                    isOpen={true}
                                >
                                    { originalPhotosRef.current[v].map((photo) => 
                                        <ImageCard
                                            download={true}
                                            variant="polaroid"
                                            src={photo.url}
                                            alt={photo.category}
                                            title={lang[photo.category] ?? photo.category}
                                            actions={
                                                <CustomIconButton
                                                    variant='light'
                                                    size="sm"
                                                    onClick={() => {
                                                        deletingPhotoRef.current = photo;
                                                        setIsDeletePhotoModalOpened(true);
                                                    }}
                                                >
                                                    <DeleteForeverIcon />
                                                </CustomIconButton>
                                            }
                                        />
                                    )
                                    }
                                </CustomDetails>
                            )
                            :
                            <></>
                        }
                    </CustomModal>
                }

                { isDeletePhotoModalOpened &&
                    <CustomModal
                        class="wfmDeletePhotoModal"
                        isOpen={isDeletePhotoModalOpened}
                        onClose={() => {
                            deletingPhotoRef.current = null;
                            setIsDeletePhotoModalOpened(false);
                        }}
                        closeButton={true}
                        footer={
                        <CustomGroup
                            variant="separated"
                        >
                            <CustomButton
                                variant="secondary"
                                onClick={() => {
                                    setIsDeletePhotoModalOpened(false);
                                }}
                            >
                                {lang.cancel}
                            </CustomButton>
                            <CustomButton onClick={deletePhoto}>{lang.delete}</CustomButton>
                        </CustomGroup>}
                    >
                        <Title>{lang.are_you_sure_wanna_delete_this_photo}</Title>
                        <ImageCard
                            download={true}
                            variant="polaroid"
                            src={deletingPhotoRef.current.url}
                            alt={deletingPhotoRef.current.category}
                            title={deletingPhotoRef.current.category}
                            imageStyle={{maxHeight: 200, maxWidth: 200}}
                        />
                    </CustomModal>
                }
            </div>


        </Fade>
    );
};
export default React.memo(InterventionManagement);

