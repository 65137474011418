import { fontWeight } from 'html2canvas/dist/types/css/property-descriptors/font-weight';
import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const ApexRadialChart = () => {
  
    const [chartData, setChartData] = useState<any>([76]);
    const [chartOptions, setChartOptions] = useState<any>({
        chart: {
                type: 'radialBar',
                height: '100%'
            },
            plotOptions: {
                radialBar: {
                    startAngle: -90,
                    endAngle: 90,
                track: {
                    background: "var(--gray-300)",
                    strokeWidth: '97%',
                    // margin: 5, // margin is in pixels
                },
                dataLabels: {
                    name: {
                    show: false
                    },
                    value: {
                    offsetY: -2,
                    fontSize: '24px',
                    fontWeight: 'bold'
                    }
                }
                }
            },
            grid: {
                padding: {
                top: -10
                }
            },
            colors: ['var(--warning)'],
            labels: ['Average Results'],
    });


  return (
    <div className='test'>
    <ReactApexChart options={chartOptions} series={[76]} type="radialBar" />
    </div>
  );
}

export default ApexRadialChart;